import config from "../config.json";

export const imagePath = () => {
  const imagePath =
    config.mode === "dev"
      ? config?.localWebsiteApi?.replace("/api/", "/images/1/")
      : config?.liveWebsiteApi?.replace("/api/", "/images/1/");

  return imagePath;
};
