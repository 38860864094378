import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fetchData from "../services/requester-website";
import { Helmet } from "react-helmet";
import { imagePath } from "../components/global";
import parse from "html-react-parser";
import PageLoader from "../assets/images/page-loader.svg";

const ExploreDestinationsDtl = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const getData = () => {
    const { name } = params;

    let reqURL = "destinations/detail/" + name;
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data && (data[0] || ""));
        setLoading(false);
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);
  return (
    <div className="dest-dtl">
      {!loading && (
        <Helmet>
          <title>{data?.title}</title>
          <meta name="description" content={data?.title} />
        </Helmet>
      )}
      <div className="container">
        {!loading && (
          <div>
            <h1>{data?.title}</h1>
            <div className="dest-dtl-wrap">
              {data?.imageURL && (
                <img src={imagePath() + data?.imageURL} alt={data?.title} />
              )}
              <div className="dest-dtl-wrap-content">
                {parse("" + data?.overview + "")}
              </div>
            </div>
          </div>
        )}

        {loading && (
          <div className="page-loader">
            <img src={PageLoader} alt="Loading" />
            Loading...
          </div>
        )}
      </div>
    </div>
  );
};

export default ExploreDestinationsDtl;
