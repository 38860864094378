import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DestinationGreece from "../assets/images-new/toppicks-greece.png";
import DestinationJapan from "../assets/images-new/toppicks-japan.png";
import DestinationBali from "../assets/images-new/toppicks-bali.png";
import DestinationSwitzerland from "../assets/images-new/toppicks-switzerland.png";
import DestinationBaku from "../assets/images-new/toppicks-baku.png";

const HomeDestinations = () => {
  const navigate = useNavigate();

  const handleExplore = (destination) => {
    let query = "";

    switch (destination) {
      case "Turkey & Greece":
        query =
          "Turkey-country-59eea4812f94933910ea3fe8-Greece-country-59eea4822f94933910ea4053";
        break;

      case "Japan":
        query = "Japan-country-59eea4822f94933910ea4041";
        break;

      case "Bali":
        query = "Bali-city-5a055c3b9f1e3826a473e30d";
        break;

      case "Switzerland":
        query = "Switzerland-country-59eea4822f94933910ea4077";
        break;

      case "Baku":
        query = "Baku-city-5a055c3b9f1e3826a473eb21";
        break;

      default:
        break;
    }

    navigate("/trip-ideas/destination-" + query);
  };

  return (
    <div className="home-destinations">
      <div className="container">
        <div className="home-title">
          <h3>Trending Destinations</h3>
          <h2>Top Picks for Your Holiday!</h2>
          <Link to="/trip-ideas" className="btn btn-primary explore-btn">
            Explore More
          </Link>
        </div>

        <div className="home-destinations-wrap">
          <div className="home-dest home-dest-1">
            <div
              className="image-box"
              onClick={() => handleExplore("Turkey & Greece")}
            >
              <img src={DestinationGreece} alt="Turkey & Greece" />
              <h4>Turkey & Greece</h4>
              <h5>
                Starting from <b>₹44,775</b>
              </h5>
            </div>
          </div>
          <div className="home-dest home-dest-2">
            <div className="image-box" onClick={() => handleExplore("Japan")}>
              <img src={DestinationJapan} alt="Japan" />
              <h4>Japan</h4>
              <h5>
                Starting from <b>₹45,134</b>
              </h5>
            </div>
          </div>
          <div className="home-dest home-dest-3">
            <div className="image-box" onClick={() => handleExplore("Bali")}>
              <img src={DestinationBali} alt="Bali" />
              <h4>Bali</h4>
              <h5>
                Starting from <b>₹44,661</b>
              </h5>
            </div>
          </div>
          <div className="home-dest home-dest-4">
            <div
              className="image-box"
              onClick={() => handleExplore("Switzerland")}
            >
              <img src={DestinationSwitzerland} alt="Switzerland" />
              <h4>Switzerland</h4>
              <h5>
                Starting from <b>₹79,495</b>
              </h5>
            </div>
          </div>
          <div className="home-dest home-dest-5">
            <div className="image-box" onClick={() => handleExplore("Baku")}>
              <img src={DestinationBaku} alt="JAPAN" />
              <h4>Baku</h4>
              <h5>
                Starting from <b>₹34,085</b>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDestinations;
