import React, { useEffect, useState } from "react";
import fetchData from "../services/requester-website";
import { Helmet } from "react-helmet";
import { imagePath } from "../components/global";
import { Link } from "react-router-dom";
import PageLoader from "../assets/images/page-loader.svg";

const ExploreDestinations = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    let reqURL = "destinations";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, -1);
  }, []);
  return (
    <div className="dest-screen">
      <Helmet>
        <title>
          Explore Destinations - Gottaholiday: Reach out to us for Expert Travel
          Assistance
        </title>
        <meta
          name="description"
          content="Have questions or need assistance? Contact Gottaholiday today for personalized support and expert advice on planning your dream vacation. Our team is here to help you every step of the way."
        />
      </Helmet>
      <div className="container">
        <h1>Explore Destinations</h1>

        {!loading && (
          <div className="dest-list">
            <div className="row">
              {data?.map((x, key) => (
                <div className="col-lg-4" key={key}>
                  <div className="dest-wrap">
                    {x?.imageURL && (
                      <img src={imagePath() + x?.imageURL} alt={x?.title} />
                    )}
                    {!x?.imageURL && (
                      <div className="dest-img-wrap">{x?.title}</div>
                    )}
                    <h2>{x?.title}</h2>
                    <p>{x?.shortDescription}</p>
                    <Link to={"" + x?.seoTitle} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {loading && (
          <div className="page-loader">
            <img src={PageLoader} alt="Loading" />
            Loading...
          </div>
        )}
      </div>
    </div>
  );
};

export default ExploreDestinations;
