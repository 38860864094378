import React, { useState, useRef } from "react";
import RatingStars from "../assets/images/rating-stars.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";

const HomeTestimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const swiperRef = useRef();

  const slideNext = () => {
    setCurrentSlide(currentSlide + 1);
    swiperRef.current?.slideNext();
  };

  const slidePrev = () => {
    setCurrentSlide(currentSlide - 1);
    swiperRef.current?.slidePrev();
  };

  return (
    <div className="home-testimonials">
      <div className="container">
        <div className="home-title">
          <h3>Testimonials</h3>
          <h2>Our Happy Explorers!</h2>

          <div className="home-testimonials-btns">
            <button
              className="btn btn-prev"
              disabled={currentSlide > 1 ? false : true}
              onClick={slidePrev}
            ></button>

            <button
              className="btn btn-next"
              disabled={currentSlide < 6 ? false : true}
              onClick={slideNext}
            ></button>

            <button
              className="btn btn-next btn-next-mobile"
              disabled={currentSlide < 6 ? false : true}
              onClick={slideNext}
            ></button>
          </div>
        </div>
        <div className="home-testimonials-wrap">
          <Swiper
            slidesPerView={1}
            centeredSlides={false}
            slidesPerGroupSkip={1}
            grabCursor={true}
            breakpoints={{
              769: {
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
            }}
            modules={[Navigation]}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="wrap-box-outer">
                <div className="wrap-box">
                  <p>
                    We had a great time and it helped that the Gottaholiday team
                    knew the place and hence the itinerary was nicely planned.
                    All the hotels in the locations were bang on! We thoroughly
                    enjoyed the tour and for all of us this was the best part.
                  </p>
                  <img src={RatingStars} alt="5 Star Rating" />
                  <h3>Vidya</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="wrap-box-outer">
                <div className="wrap-box">
                  <p>
                    Thank you for organising the visa papers so professionally.
                    I saw others struggle with incomplete forms (pre-trip). A
                    big thank you to the entire team at Gottaholiday for all the
                    planning and arrangements. It was a great trip and we
                    managed to do full justice to the itinerary (post trip)
                  </p>
                  <img src={RatingStars} alt="5 Star Rating" />
                  <h3>Murali</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="wrap-box-outer">
                <div className="wrap-box">
                  <p>
                    We have completed our trip and thanks for all the support
                    Gottaholiday has expended. No doubt, I am very happy with
                    the proactive support that you have extended. Thank you
                    Gottaholiday for making this an unforgettable holiday for
                    us!
                  </p>
                  <img src={RatingStars} alt="5 Star Rating" />
                  <h3>Anuj</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="wrap-box-outer">
                <div className="wrap-box">
                  <p>
                    We have returned from a splendid holiday and thanks to the
                    entire Gottaholiday team. Everything was so well planned,
                    executed and seamless. Deep appreciation for all the effort.
                    Now Please start planning for our next holiday.
                  </p>
                  <img src={RatingStars} alt="5 Star Rating" />
                  <h3>Vikram</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="wrap-box-outer">
                <div className="wrap-box">
                  <p>
                    It is a great association we have with the Gottaholiday
                    team. It is a complex industry but what we like most is the
                    knowledge, the liaisoning, the partners they work with and
                    their quick response to any issue faced during the trip.
                  </p>
                  <img src={RatingStars} alt="5 Star Rating" />
                  <h3>Sanjay</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="wrap-box-outer">
                <div className="wrap-box">
                  <p>
                    It was such an amazing trip. The fact that we saved money
                    and we were placed walkable in most places helped a lot. To
                    find so many hotels in a tour like this and all good ones
                    was great. Thanks again we had a fabulous time.
                  </p>
                  <h3>Srikant</h3>
                  <img src={RatingStars} alt="5 Star Rating" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default HomeTestimonials;
