import React, { useState } from "react";
import HomeLogo from "../assets/images-new/home-logo.svg";
import { Link } from "react-router-dom";

const HeaderHome = () => {
  const [isMobile, setMobile] = useState(false);

  const handleMobileMenu = () => {
    setMobile(!isMobile);
  };
  return (
    <header className="home-header">
      <div className="container">
        <div className="row">
          <div className="col-3">
            <Link to="/" className="logo">
              <img src={HomeLogo} alt="gottaholiday.com" />
            </Link>
          </div>

          <div className="col-9 text-end">
            <Link
              className="mobile-home-btn d-none"
              to="/trip-ideas"
              onClick={handleMobileMenu}
            >
              Trip Ideas
            </Link>
            <button className="header-menu-icon" />
            <div className={"navbar" + (isMobile ? " show-mobile-menu" : "")}>
              <ul className="list-unstyled">
                <li>
                  <Link
                    className="highlight"
                    to="/trip-ideas"
                    onClick={handleMobileMenu}
                  >
                    Trip Ideas
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderHome;
