import React, { useState, useEffect } from "react";
import Config from "../config.json";
import { Link, useParams } from "react-router-dom";
import authRequest from "../services/auth-requester";
import fetchData from "../services/requester";
import { currencyFormatter } from "../components/formatter";
import PlayVideoIcon from "../assets/images/play_video_icon_dark.svg";
import ItineraryIcon from "../assets/images/details-itinerary-route.svg";
import HotelIcon from "../assets/images/detail-sec-hotel.svg";
import TransportIcon from "../assets/images/detail-sec-transfer.svg";
import ActivityIcon from "../assets/images/detail-sec-activity.svg";
import OtherInclusionIcon from "../assets/images/detail-sec-other.svg";
import InclusionIcon from "../assets/images/details-check.svg";
import ExcusionIcon from "../assets/images/details-uncheck.svg";
import WhatsappIcon from "../assets/images/whatsapp_icon-new.svg";
import CustomizeIcon from "../assets/images/customize_icon-new.svg";
import InclusionsIcons from "../components/inclusions-icons";
import PageLoader from "../assets/images/page-loader.svg";
import { Helmet } from "react-helmet";
import HomeTestimonials from "../components/home-testimonials";
import HomeNewsletter from "../components/home-newsletter";

const TripIdeasDetails = () => {
  const { liveMode } = Config;
  const whatsappNumber = "+919372999307";
  const [data, setData] = useState([]);
  const [item, setItem] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const [isPlayVideo, setPlayVideo] = useState(false);
  const [daywiseItinerary, setDaywiseItinerary] = useState([]);

  const getAuth = () => {
    authRequest((token) => {
      token && getData();
    });
  };

  const getData = () => {
    const { name } = params;
    // let req = liveMode ? "packages/getTrip/" + name : "trip-details.json";

    let req = liveMode
      ? "packages/getTripBySEOLink/" + name
      : "trip-details.json";

    let obj = "";
    fetchData(
      req,
      obj,
      (data) => {
        let res = liveMode ? data : data?.find((x) => x?.id === name);
        setData(res || []);
        generateItinerary(res || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const handleMore = (item) => {
    setItem(item);
  };

  const handleVideo = () => {
    setPlayVideo(!isPlayVideo);
  };

  const { itinerariesNew, hotels, activities, transfers, otherInclusionsList } =
    data;

  let otherInclusionsListDisplay =
    otherInclusionsList?.filter(
      (x) =>
        x?.type !== "Package Start Date" &&
        x?.type !== "Package End Date" &&
        x?.type !== "Day at leisure"
    ) || [];

  const generateItinerary = (data) => {
    const { itinerariesNew } = data || [];

    let daywiseItinerary = [];

    itinerariesNew?.forEach((entry) => {
      entry.dates.forEach((dateEntry) => {
        const existingEntryIndex = daywiseItinerary.findIndex(
          (item) => item.date === dateEntry.date
        );
        if (existingEntryIndex !== -1) {
          daywiseItinerary[existingEntryIndex].events.push(...dateEntry.events);
        } else {
          daywiseItinerary.push({
            date: dateEntry.date,
            city: entry.city,
            events: dateEntry.events,
          });
        }
      });
    });

    setDaywiseItinerary(daywiseItinerary);
  };

  const formatInclusions = () => {
    let originalArray = [];

    hotels && hotels.length > 0 && originalArray.push("Hotel");
    activities && activities.length > 0 && originalArray.push("Activity");
    transfers && transfers.length > 0 && originalArray.push("Transfer");

    otherInclusionsList &&
      otherInclusionsList?.map((x) => originalArray.push(x?.type));

    originalArray = originalArray.map((item) => item.trim());

    originalArray = originalArray.map((item) =>
      item === "Transfer" ? "Transport" : item
    );

    originalArray = [...new Set(originalArray)];

    originalArray = originalArray.filter((item) => item !== "Day at leisure");
    originalArray = originalArray.filter((item) => item !== "");

    return originalArray;
  };

  useEffect(() => {
    liveMode ? getAuth() : getData();
    window.scrollTo(0, -1);
  }, []);

  return (
    <React.Fragment>
      <div className="trip-details">
        {!loading && (
          <Helmet>
            <title>
              Explore {data?.seoTitle} | Customized Holidays by Gottaholiday
            </title>
            <meta
              name="description"
              content={
                "Discover " +
                data?.seoTitle +
                " Holiday Package with Gottaholiday. Explore a day-wise itinerary, highlights, inclusions, and more. Connect with our experts in one click. Start planning now!"
              }
            />
          </Helmet>
        )}
        <div className="container">
          {!loading && (
            <div>
              <div className="trip-details-info">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="trip-details-info-title">
                      <h1>{data?.name}</h1>
                      <h5>{data?.cities?.toString()?.replaceAll(",", ", ")}</h5>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="trip-details-info-price">
                      <div className="text-secondary small">Starting from</div>
                      <h3 className="trip-details-price">
                        {currencyFormatter(parseInt(data?.startPrice) || 0)}
                      </h3>
                      <div className="text-secondary small">per person</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="trip-details-img">
                {itinerariesNew && itinerariesNew[0] && (
                  <img
                    src={itinerariesNew[0]?.cityImage?.landscapeImage}
                    alt=""
                  ></img>
                )}

                {itinerariesNew &&
                  itinerariesNew[0] &&
                  itinerariesNew[0]?.cityImage?.videoUrl && (
                    <button
                      className="btn itinerary-video-btn"
                      onClick={handleVideo}
                    >
                      <img src={PlayVideoIcon} alt="" />
                      Itinerary Video
                    </button>
                  )}
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="trip-details-info-btn">
                    <Link
                      to={
                        "https://web.whatsapp.com/send?phone=" +
                        whatsappNumber +
                        "&text=" +
                        encodeURIComponent(
                          "I am interested in this package: " + window.location
                        )
                      }
                      className="btn whatsapp-btn"
                      target="_blank"
                    >
                      <img src={WhatsappIcon} alt="" />
                      Enquire Now
                    </Link>
                    <Link
                      to={
                        "https://wa.me/" +
                        whatsappNumber +
                        "?text=" +
                        encodeURIComponent(
                          "I am interested in this package: " + window.location
                        )
                      }
                      className="btn whatsapp-btn whatsapp-btn-mobile d-none"
                      target="_blank"
                    >
                      <img src={WhatsappIcon} alt="" />
                      Enquire Now
                    </Link>
                    <Link
                      to={
                        "https://web.whatsapp.com/send?phone=" +
                        whatsappNumber +
                        "&text=" +
                        encodeURIComponent(
                          "I would like to customize this package: " +
                            window.location
                        )
                      }
                      className="btn whatsapp-btn whatsapp-btn-orange"
                      target="_blank"
                    >
                      <img src={CustomizeIcon} alt="" />
                      Customize Package
                    </Link>
                    <Link
                      to={
                        "https://wa.me/" +
                        whatsappNumber +
                        "?text=" +
                        encodeURIComponent(
                          "I would like to customize this package: " +
                            window.location
                        )
                      }
                      className="btn whatsapp-btn whatsapp-btn-mobile whatsapp-btn-orange d-none"
                      target="_blank"
                    >
                      <img src={CustomizeIcon} alt="" />
                      Customize Package
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="trip-details-info-sec">
                    <h4>Tour includes</h4>
                    <div className="trip-details-info-icons">
                      {formatInclusions()?.map((inclusion, key) => (
                        <div key={key} className="results-inclusion-icon">
                          <InclusionsIcons icon={inclusion} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h2>
                  <img
                    src={ItineraryIcon}
                    style={{ height: "42px" }}
                    className="me-3"
                    alt=""
                  />
                  Itinerary
                </h2>
                <div className="trip-details-itinerary">
                  {daywiseItinerary?.map((x, key) => (
                    <div className="trip-details-itinerary-day" key={key}>
                      <h5>
                        Day {key + 1}
                        {/* <span className="ms-3 small">{x?.date}</span> */}
                      </h5>
                      <h6>{x?.city}</h6>

                      <div className="mt-3">
                        {x?.events?.map((y, key1) => (
                          <p key={key1}>
                            {y?.title.split(" - ")[0]?.replaceAll(", null", "")}

                            {y?.text ? ": " + y?.text : ""}
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {hotels && hotels?.length > 0 && (
                <div className="trip-details-sec">
                  <h2>
                    <img
                      src={HotelIcon}
                      style={{ height: "30px" }}
                      className="me-3"
                      alt=""
                    />
                    Hotels
                  </h2>
                  <div className="trip-details-items trip-details-hotels">
                    {hotels &&
                      hotels?.map((x, key) => (
                        <div key={key} className="trip-details-item">
                          <div className="row g-4">
                            <div className="col-lg-4 position-relative">
                              <img
                                className="trip-details-thumb"
                                src={x?.room_images[0].replace("_t", "_s")}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h3>{x?.hotelName}</h3>
                              <div className="text-secondary small">
                                {x?.address}
                              </div>
                              <div className="text-secondary small">
                                {x?.destination?.city1 +
                                  ", " +
                                  x?.destination?.country?.country}
                              </div>
                              <h4>{x?.rooms[0]?.name}</h4>
                              <div className="text-secondary small">
                                {x?.rooms[0]?.roomSize}
                              </div>
                              <div className="text-secondary small">
                                {x?.rooms[0]?.type.replaceAll(",", ", ")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {activities && activities?.length > 0 && (
                <div className="trip-details-sec">
                  <h2>
                    <img
                      src={ActivityIcon}
                      style={{ height: "30px" }}
                      className="me-3"
                      alt=""
                    />
                    Activities
                  </h2>
                  <div className="trip-details-items trip-details-activities">
                    {activities &&
                      activities?.map((x, key) => (
                        <div key={key} className="trip-details-item">
                          <div className="row g-4">
                            <div className="col-lg-4 position-relative">
                              <img
                                className="trip-details-thumb"
                                src={x?.image_url}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h3>{x?.title?.split(" - ")[0]}</h3>
                              <div className="row">
                                <div className="col-lg-6">
                                  <h4>Inclusions</h4>
                                  <ul
                                    className={
                                      "list-unstyled" +
                                      (key === item ? " more-dtl-show" : "")
                                    }
                                  >
                                    {x?.inclusions?.map((y, key1) => (
                                      <li key={key1}>
                                        <img src={InclusionIcon} alt="" />
                                        <span>{y}</span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div className="col-lg-6">
                                  <h4>Exclusions</h4>
                                  <ul
                                    className={
                                      "list-unstyled" +
                                      (key === item ? " more-dtl-show" : "")
                                    }
                                  >
                                    {x?.exclusions?.map((y, key2) => (
                                      <li key={key2}>
                                        <img src={ExcusionIcon} alt="" />
                                        <span>{y}</span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              {(x?.inclusions?.length > 4 ||
                                x?.exclusions?.length > 4) && (
                                <button
                                  className="btn btn-sm btn-link"
                                  onClick={() => handleMore(key)}
                                >
                                  More
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {transfers && transfers?.length > 0 && (
                <div className="trip-details-sec">
                  <h2>
                    <img
                      src={TransportIcon}
                      style={{ height: "30px" }}
                      className="me-3"
                      alt=""
                    />
                    Transfers
                  </h2>
                  <div className="trip-details-items trip-details-transfers">
                    {transfers &&
                      transfers?.map((x, key) => (
                        <div key={key} className="trip-details-item">
                          <div className="row g-4">
                            <div className="col-lg-4 position-relative">
                              <img
                                className="trip-details-thumb"
                                src={x?.image_url}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-8">
                              <h3>
                                {x?.port?.name?.replaceAll(", null", "")} -{" "}
                                {x?.resort?.name?.replaceAll(", null", "")}
                              </h3>
                              <div className="text-secondary small">
                                Transfer Type: {x?.transferType?.type}
                              </div>
                              <h4>Includes</h4>
                              <div className="small text-secondary">
                                {x?.title2?.replaceAll(", null", "")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {otherInclusionsListDisplay &&
                otherInclusionsListDisplay?.length > 0 && (
                  <div className="trip-details-sec">
                    <h2>
                      <img
                        src={OtherInclusionIcon}
                        style={{ height: "30px" }}
                        className="me-3"
                        alt=""
                      />
                      Other Inclusions
                    </h2>
                    <div className="trip-details-items trip-details-transfers trip-details-others mt-4">
                      {otherInclusionsListDisplay &&
                        otherInclusionsListDisplay.map((x, key) => (
                          <div key={key} className="trip-details-item">
                            <div className="row g-4">
                              {x?.image_url && (
                                <div className="col-lg-4 position-relative">
                                  <img
                                    className="trip-details-thumb"
                                    src={x?.image_url}
                                    alt=""
                                  />
                                </div>
                              )}
                              <div className="col-lg-8">
                                <h3>{x?.type}</h3>
                                <div className="text-secondary small">
                                  {x?.description}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

              <div className="row mt-5">
                <div className="col-lg-6">
                  <div className="trip-details-info-btn">
                    <Link
                      to={
                        "https://web.whatsapp.com/send?phone=" +
                        whatsappNumber +
                        "&text=" +
                        encodeURIComponent(
                          "I am interested in this package: " + window.location
                        )
                      }
                      className="btn whatsapp-btn"
                      target="_blank"
                    >
                      <img src={WhatsappIcon} alt="" />
                      Enquire Now
                    </Link>
                    <Link
                      to={
                        "https://wa.me/" +
                        whatsappNumber +
                        "?text=" +
                        encodeURIComponent(
                          "I am interested in this package: " + window.location
                        )
                      }
                      className="btn whatsapp-btn whatsapp-btn-mobile d-none"
                      target="_blank"
                    >
                      <img src={WhatsappIcon} alt="" />
                      Enquire Now
                    </Link>
                    <Link
                      to={
                        "https://web.whatsapp.com/send?phone=" +
                        whatsappNumber +
                        "&text=" +
                        encodeURIComponent(
                          "I would like to customize this package: " +
                            window.location
                        )
                      }
                      className="btn whatsapp-btn whatsapp-btn-orange"
                      target="_blank"
                    >
                      <img src={CustomizeIcon} alt="" />
                      Customize Package
                    </Link>
                    <Link
                      to={
                        "https://wa.me/" +
                        whatsappNumber +
                        "?text=" +
                        encodeURIComponent(
                          "I would like to customize this package: " +
                            window.location
                        )
                      }
                      className="btn whatsapp-btn whatsapp-btn-mobile whatsapp-btn-orange d-none"
                      target="_blank"
                    >
                      <img src={CustomizeIcon} alt="" />
                      Customize Package
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          {loading && (
            <div className="page-loader">
              <img src={PageLoader} alt="Loading" />
              Loading...
            </div>
          )}
        </div>

        {!loading && isPlayVideo && (
          <div className="itinerary-video-wrap">
            <iframe
              className="itinerary-video-iframe"
              src={
                "https://www.youtube.com/embed/" +
                itinerariesNew[0]?.cityImage?.videoUrl +
                "&autoplay=1&mute=1"
              }
              key={data?.name}
              title={data?.name}
              allowFullScreen="allowFullScreen"
            ></iframe>
            <button
              className="btn itinerary-video-close-btn"
              onClick={handleVideo}
            >
              X
            </button>
          </div>
        )}

        <div className="mt-5" style={{ background: "#fff" }}>
          <HomeTestimonials />
          <HomeNewsletter />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TripIdeasDetails;
