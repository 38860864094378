import React from "react";
import HomeBanner from "../components/home-banner";
import HomeDestinations from "../components/home-destinations";
import HomeHowItWorks from "../components/home-how-it-works";
import HomeWhyTripDarwin from "../components/home-why-trip-darwin";
import HomeTestimonials from "../components/home-testimonials";
import HomeComingSoon from "../components/home-coming-soon";
import { Helmet } from "react-helmet";
import HomeTroughEyes from "../components/home-trougheyes";
import HomeTrending from "../components/home-trending";
import HomeNewsletter from "../components/home-newsletter";
import HeaderHome from "../components/header-home";

const Home = () => {
  const css = `header { display: none; }`;

  return (
    <div className="home-screen">
      <style>{css}</style>
      <Helmet>
        <title>
          Book Customized Tour Packages | Personalized Travel with Gottaholiday
        </title>
        <meta
          name="description"
          content="Looking for a customized travel experience? With GottaHoliday, you can personalize your tour package to match your preferences. Choose from our curated options or share your requirements via our chat, and plan your dream getaway with ease!"
        />
      </Helmet>
      <HeaderHome />
      <HomeBanner />
      <HomeDestinations />
      <HomeTroughEyes />
      <HomeTrending />
      <HomeWhyTripDarwin />
      <HomeHowItWorks />
      <HomeComingSoon />
      <HomeTestimonials />
      <HomeNewsletter />
    </div>
  );
};

export default Home;
