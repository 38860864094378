import config from "../config.json";

const appPath =
  config?.mode === "dev" ? config?.localWebsiteApi : config?.liveWebsiteApi;

const fetchData = (reqURL, reqOBJ, callback, reqMethod) => {
  return fetch(appPath + reqURL, {
    method: reqMethod || "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: reqOBJ ? JSON.stringify(reqOBJ) : null,
  })
    .then((res) => res?.text())
    .then((data) => {
      try {
        callback(JSON.parse(data));
      } catch (e) {
        callback(data);
      }
    })
    .catch((e) => callback(e));
};

export default fetchData;
